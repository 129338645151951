import React from "react";
// import PropTypes from "prop-types";

// import classNames from 'classnames';

import Icon from '../Common/Icon/Icon';

import styles from './Marker.module.scss';

class Marker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.id);
    }
  }

  render() {
    return (
      <div className={styles.marker} onClick={ this.onClick }>
        <Icon className={styles.icon} icon="pr-pin" size="large"/>
      </div>
    );
  }
}

Marker.propTypes = {

}

export default Marker;


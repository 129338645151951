import React from "react";
// import PropTypes from "prop-types";
import classNames from 'classnames';
import { isWithinInterval, getDay, setHours, setMinutes } from 'date-fns';

import styles from './OpeningTime.module.scss';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

// Uncomment for next opening day
// const capitalDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * Component that handles displaying the days current open
 * or closed state
 */
const OpeningTime = ({ className, opening }) => {
  const d = new Date();
  const currDay = getDay(d);


  // If there is no info about this restaurants open status on
  // this day, display nothing
  if (!opening[days[currDay]]) {
    return null;
  }


  // Creates date objects, with the specific hours/minutes
  // attached to a date (today)
  const getDate = (da, timeStr) => {
    const t = timeStr.split(':').map(t => parseInt(t));
    return setMinutes(setHours(da, t[0]), t[1]);
  }


  // Method to determine the open status
  // of the restaurant
  const isOpen = (times) => {
    // If there is one result ('closed')
    // force it to be closed
    if (times.length === 1) {
      return false;
    }

    // Predefine the likelihood of being closed
    let o = false;

    // Loop through the times in pairs as theyre
    // formatted in an open/close manner
    for (let i = 0; i < times.length; i += 2) {
      const within = isWithinInterval(d, {
        start: getDate(d, times[i]),
        end: getDate(d, times[i + 1]),
      });

      if (within) {
        o = true;
        break;
      }
    }

    return o;
  }

  // Uncomment for next opening day
  // const nextOpen = () => {
  //   const nextKey = (currDay + 1) % 7;
  //   const next = opening[days[nextKey]];
  //   if (next[0] === 'Closed') {
  //     return null;
  //   }

  //   return <span className={styles.next}>{ capitalDays[nextKey] } { next[0] }</span>;
  // }

  const open = isOpen(opening[days[currDay]]);

  const cls = classNames(
    styles.time,
    className,
    {
      [styles.closed]: !open
    }
  );

  return (
    <div className={ cls }>
      { open ? <span className={styles.label}>Open Now</span> : <span className={styles.label}>Closed</span> }
      {/* {
        !open ? nextOpen() : null
      } */}
    </div>
  );
}

export default OpeningTime;


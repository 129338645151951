import React, { useRef } from "react"
import { connect } from 'react-redux'
// import PropTypes from "prop-types"

import classNames from 'classnames';
import anime from 'animejs/lib/anime.es.js';
import { navigate } from '@reach/router'

import LocationTile from '../LocationTile/LocationTile';
import MapContainer from '../Map/MapContainer';
import SearchHeader from "./SearchHeader";
import Credits from '../Layout/Credits';

import { ACTIONS } from '../../state/createStore';

import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./Home.module.scss"


/**
 * Method to return the search query related to
 * the search method
 */
const getParams = () => {
  if (typeof document === 'undefined') {
    return {
      search: false,
      price: false
    }
  }

  const params = (new URL(document.location)).searchParams;

  const search = params.get('search');
  const price = params.get('price');
  const tag = params.getAll('tag');

  return { search, price, tag };
}

const Home = ({ locations, mapView, openSearch }) => {
  const cls = classNames(
    styles.home,
    {
      [styles.mapView]: mapView
    }
  );


  /**
   * Method to just redirect away from search parameters as a reset
   */
  const resetSearch = () => {
    navigate("/");
  }


  // Creates a ref for the search container to scroll
  const listRef = useRef(null);


  /**
   * Method to scroll to the correct tile when selecting the
   * map points on desktop
   *
   * @param {string} id
   */
  const onLocation = (id) => {
    // Unecessary to even run for tablet and below
    if (window.matchMedia('screen and (min-width: 768px)')) {
      const clsn = `.location-tile-${ id }`;
      const el = listRef.current.querySelector(clsn);

      const elTop = el.getBoundingClientRect().top;
      const containerTop = el.parentElement.getBoundingClientRect().top;

      const currentScroll = el.parentElement.scrollTop;
      const scrollTarget = elTop - containerTop;

      const speed = 1000;

      anime({
        targets: listRef.current,
        scrollTop: (currentScroll + scrollTarget) - 20,
        duration: speed,
        easing: 'easeOutQuint'
      })
    }
  };


  const query = getParams();

  /**
   * Method for determining which locations make it through
   */
  const filterSearch = ({ node }) => {
    // if ssr return true always
    if (typeof window === 'undefined') {
      return true;
    }

    // If no query return true
    if (!window.location.search) {
      return true;
    }

    const { tags, name, type, price } = node;

    // Always match on lower case
    const _search = query.search.toLowerCase();


    // If price has been added and the price is greater
    // than the allocated price, it is false
    if (query.price && price > query.price) {
      return false;
    }


    // If tags have been added check to see
    // if ALL the tags are present to return true
    // i.e you'd want both 'Restaurant & Vegan'
    if (query.tag && query.tag.length) {
      let _tags = tags.map(t => t.toLowerCase());

      let found = 0;

      query.tag.forEach(t => {
        if (_tags.includes(t)) {
          found++;
        }
      });

      // Make sure all the tags are in this location
      if (query.tag.length !== found) {
        return false;
      }
    }

    // If search is empty, it equals 'any'
    if (query.search === '') {
      return true;
    }

    // If search matches location name
    if (name.toLowerCase().includes(_search)) {
      return true;
    }

    // If search matches type
    if (type.toLowerCase().includes(_search)) {
      return true;
    }

    // Checkes to see if search matches any tag
    const tag = tags.find(t => t.toLowerCase().includes(_search));

    if (tag) {
      return true;
    }

    // Everything else must be false
    return false;
  }

  let locationsList = locations.list
    .slice()
    // Sort by number in list
    .sort((a, b) => {
      return a.node.number - b.node.number;
    })
    // Filter by search
    .filter(filterSearch);

  return (
    <section className={ cls }>
      <div ref={ listRef } className={styles.list}>
        <SearchHeader onClick={ openSearch } onReset={ resetSearch } {...query}/>

        { locationsList.length ? locationsList.map(d => (
          <LocationTile className={styles.listItem} key={d.node.id} {...d.node} />
        )) : (
          <div className={styles.listEmpty}>
            <span>No results for this search</span>
            <span onClick={openSearch}>Refine it?</span>
          </div>
        ) }

        <Credits/>
      </div>

      <MapContainer
        locations={ locationsList }
        onLocation={ onLocation }
        className={ styles.map }/>
    </section>
  );
}

Home.propTypes = {
}

const mapStateToProps = ({ mapView }) => {
  return { mapView }
}

const mapDispatchToProps = dispatch => {
  return {
    openSearch: () => dispatch({ type: ACTIONS.OPEN_SEARCH })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)

import React, { useState } from "react";

import classNames from 'classnames';

import { Link } from 'gatsby';

import Map from './Map';
import LocationInfoCard from '../Common/LocationInfoCard/LocationInfoCard';

import styles from './MapContainer.module.scss';

const MapContainer = ({ locations, className, onLocation = () => {} }) => {

  const _locations = locations.slice().sort((a, b) => {
    return b.node.coords.lat - a.node.coords.lat;
  });

  const [mapSelected, setSelected] = useState(false);
  const [selectedShow, setSelectedShow] = useState(false);

  const onMapSelect = (id) => {
    if (id === mapSelected) {
      setSelectedShow(false);
      setTimeout(() => {
        setSelected(false);
      }, 500);
    } else {
      onLocation(id);

      setSelected(id);
      setTimeout(() => {
        setSelectedShow(true);
      }, 50);
    }
  }

  const getMarker = id => {
    return _locations.find(l => l.hasOwnProperty('node') ? l.node.id === id : false);
  }

  const cls = classNames(
    styles.selected,
    {
      [styles.selectedShow]: selectedShow
    }
  );

  const current = getMarker(mapSelected);

  return (
    <div className={className}>
      <Map
        onSelect={ onMapSelect }
        markers={_locations.map(({ node }) => {
          if (!node) {
            return null;
          }

          return {
            ...node
          };
        })}/>
      <Link to={ current && current.hasOwnProperty('node') ? current.node.fields.slug : '/'} className={cls}>
        { (mapSelected && current) ? <LocationInfoCard {...current.node} vertical/> : null }
      </Link>
    </div>
  );
}

export default MapContainer;


import React from "react";
import ReactDOM from 'react-dom';
// import PropTypes from "prop-types";

import classNames from 'classnames';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';

import Marker from './Marker';

import styles from './Map.module.scss';

const START_LAT = 51.492907;
const START_LNG = -0.098436;
const START_ZOOM = 13.5;
const SELECTED_ZOOM = 15;

class Map extends React.Component {
  constructor(props) {
    super(props);

    mapboxgl.accessToken = 'pk.eyJ1IjoicGV0ZXJyb2RlbjkzIiwiYSI6ImNqeXl1YWd1MTFrdTEzY3BobjVxa3NoMzUifQ.au3Z5_INqkxxbFSFRh5DGw';

    this.state = {
      lat: START_LAT,
      lng: START_LNG,
      zoom: START_ZOOM,
      bounds: [
        [-0.118294, 51.481169],
        [-0.080184, 51.502670]
      ]
    };
  }

  componentDidUpdate() {
    this.updateMarkers(this.props.markers);
  }

  componentWillUnmount() {
    this.map.remove();
  }

  createRef = (ref) => {
    this.ref = ref;

    if (this.ref) {
      this._createMap(this.ref);
    }
  }

  getMarker(id) {
    let idx = this.getMarkerIndex(id);

    return idx >= 0 ? this.markers[idx] : false;
  }

  getMarkerIndex(id) {
    return this.markers.findIndex(m => id === m.id)
  }

  _createMap(el) {
    const { lng, lat, zoom, bounds } = this.state;

    this.map = new mapboxgl.Map({
      container: el,
      style: 'mapbox://styles/peterroden93/cjzo8r1b207by1cn4feff5l7o',
      center: [lng, lat],
      zoom,
      maxBounds: bounds
    });

    this.map.on('move', () => {
      // const { lng, lat } = this.map.getCenter();

      this.setState({
        zoom: this.map.getZoom().toFixed(2)
      });
    });

    // Resizes in case it needs it
    setTimeout(() => this.map.resize(), 100);

    // Add navigation
    this.nav = new mapboxgl.NavigationControl({
      showCompass: false
    });
    this.map.addControl(this.nav);


    this.markers = [];
    this.createMarkers(this.props.markers);
  }

  createMarkers(markers) {
    markers.forEach((m, idx) => {
      this._createMarker(m, idx);
    })
  }

  onMarkerClick = (id) => {
    const markerIdx = this.getMarkerIndex(id);

    const target = {
      center: [this.markers[markerIdx].props.coords.lng, this.markers[markerIdx].props.coords.lat],
      offset: [0, 30]
    };

    if (this.state.zoom < SELECTED_ZOOM) {
      target.zoom = SELECTED_ZOOM;
    }

    this.map.easeTo(target);

    this.props.onSelect(id);
  }

  _createMarker(el) {
    const container = document.createElement('div');

    ReactDOM.render(
      (
        <Marker key={el.id} {...el} onClick={this.onMarkerClick}/>
      ),
      container
    );

    const tooltip = new mapboxgl.Marker(container, {
      offset: [0, 0]
    })
      .setLngLat([el.coords.lng, el.coords.lat])
      .addTo(this.map);

    this.markers.push({
      id: el.id,
      props: { ...el },
      marker: tooltip,
      container
    });
  }

  updateMarkers(markers) {
    const ids = this.markers.map(m => m.id);

    markers.forEach(m => {
      const idx = ids.indexOf(m.id);

      if (idx < 0) {
        this._createMarker(m);
      } else {
        ids.splice(idx, 1);
      }
    })

    for (let i = 0; i < ids.length; i++) {
      const markerIdx = this.getMarkerIndex(ids[i]);
      const marker = this.markers[markerIdx];

      marker.marker.remove();

      this.markers.splice(markerIdx, 1);
    }
  }

  render() {
    const { className } = this.props;

    const cls = classNames(
      styles.map,
      className
    );

    return (
      <div className={cls} ref={this.createRef}/>
    )
  }
}

Map.propTypes = {

}

Map.defaultProps = {
  onSelect: () => {}
}

export default Map;


import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

import Price from '../Price';
import OpeningTime from "../OpeningTime/OpeningTime";
import NumberCircle from "../NumberCircle";

import styles from './LocationInfoCard.module.scss';

/**
 * Persistent info area that goes underneath the cards but is also
 * used on the map page on mobile
 */
const LocationInfoCard = ({ name, type, vertical = false, className, price, opening, number }) => {
  const cls = classNames(
    styles.container,
    {
      [styles.vertical]: vertical
    },
    className
  );

  return (
    <div className={ cls }>
      <div className={styles.info}>
        <p className={styles.title}>{ name.split(' ').map((p, index) => <span key={index}>{p}</span>) }</p>
        <ul className={styles.subtitle}>
          <li>{ type }</li>
          <li><Price price={price}/></li>
        </ul>
      </div>
      <div className={styles.meta}>
        <OpeningTime opening={opening}/>

        <div className={styles.circle}>
          <NumberCircle
            number={number}/>
        </div>
      </div>
    </div>
  );
}

LocationInfoCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  price: PropTypes.number,
  opening: PropTypes.object,
  number: PropTypes.number
}

export default LocationInfoCard;


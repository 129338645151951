import React from "react"
import { graphql } from "gatsby"

import Home from '../components/Home/Home';
import SEO from "../components/seo"

const IndexPage = ({ data }) => {

  return (
    <>
      <SEO title="Home" />
      <Home locations={data.locations}/>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    locations: allLocationsYaml {
      list: edges {
        node {
          id
          name
          type
          coords {
            lat
            lng
          },
          fields {
            slug
          },
          image {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          price,
          tags,
          opening {
            friday
            monday
            saturday
            sunday
            thursday
            tuesday
            wednesday
          },
          number
        }
      }
    }
  }
`;

import React from "react"
// import PropTypes from "prop-types"
import classNames from 'classnames';

import Price from "../Common/Price";
import Icon from "../Common/Icon/Icon";

import styles from "./SearchHeader.module.scss"

const SearchHeader = ({ className, search, price, tag, onClick, onReset }) => {
  const cls = classNames(
    styles.header,
    className
  );

  if (!search && !price && (!tag || tag.length === 0)) {
    return null;
  }

  return (
    <div className={ cls }>
      <h3>Results for:</h3>
      <h2 onClick={onClick}>{ search ? search : 'Any' }</h2>
      { tag.length ? <h3 onClick={onClick}>With tags: { tag.join(', ') }</h3> : null }
      <Price className={styles.price} price={parseInt(price, 10)}/>
      <button className={styles.clear} onClick={() => onReset()}>
        <Icon className={styles.clearIcon} size="free" icon="close-circle"/>Clear search
      </button>
    </div>
  )
};

SearchHeader.propTypes = {
}

export default SearchHeader

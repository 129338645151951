import React from "react"
// import PropTypes from "prop-types"
import classNames from 'classnames';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import LocationInfoCard from "../Common/LocationInfoCard/LocationInfoCard";

import styles from "./LocationTile.module.scss"

const LocationTile = ({ id, className, name, type, fields, image, tags, price, opening, number }) => {
  const cls = classNames(
      styles.tile,
      className,
      `location-tile`,
      `location-tile-${ id }`
  );

  return (
      <Link to={ fields.slug } className={ cls }>
          <Image
            fluid={ image.childImageSharp.fluid }
            backgroundColor={'#00b569'}
            className={ styles.image }/>
          <LocationInfoCard
            className={styles.info}
            name={ name }
            type={ type }
            tags={ tags }
            price={ price }
            opening={ opening }
            number={ number }/>
      </Link>
  );
}

LocationTile.propTypes = {
}

export default LocationTile
